@import "../../themes/generated/variables.base.scss";

.login-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }
}
// #login-page{
//   background-size: cover;
//   width: 100%;
//   height: 100%;
// }

.modal-login{
  padding: 2rem;
  border-radius: 10px!important;
}
.logo-login{
  margin-left: auto;
  margin-right: auto;
  width: 50px;
}
.login-form > .dx-form > .dx-layout-manager > .dx-widget > .dx-box-flex > .dx-box-item > .dx-item-content > .dx-first-col > .dx-button-default{
  height: 36px;
  border-radius: 10px;
}

.login-error-message{
  color: #c6262e;
}
.btn-google{
  width: 215px;
}
.cursor-pointer{
  cursor: pointer;
}
.modal-login > .content{
  padding: 0!important;
  padding-bottom: 0!important;
}

.dimmer{
  background-color: unset!important;
  // background-color: rgba(0,0,0,.1)!important;
}
.login-page{
  background-size: cover;
  width: 100%;
  height: 100%;
  filter: blur(50px);
}
.modal-login>.content{
  background: transparent!important;
  /* background: rgba(0, 0, 0, 0.2)!important; */
}
.modal-login{
  // background: inherit!important;
  // background: rgba(255, 255, 255, 0.8)!important;
  box-shadow: 1px 3px 3px 0 rgb(0 0 0 / 50%), 1px 3px 15px 2px rgb(0 0 0 / 50%);
}
